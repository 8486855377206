// 其他的接口
import axios from '../axios'

const other = {
  //上传表情包
  expressionUpload(params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/expressionUpload',
      method: "post",
      data: params,
    })
  },
  //上传表情包列表
  queryUploadPageList(params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/queryUploadPageList',
      method: "get",
      params,
    })
  },
  // 表情包修改
  expressionUpdate(expressionId, params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/expressionUpdate/' + expressionId,
      method: "put",
      data: params,
    })
  },
  // 删除表情包
  deleteExpression(expressionId) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/expression/' + expressionId,
      method: "delete",
    })
  },
  // 表情包详情
  getExpressionDetail(expressionId) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/expressionDetail/' + expressionId,
      method: "get",
    })
  },
  // 上传表情包列表
  getUploadExpression(params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/queryUploadPageList',
      method: "get",
      params,
    })
  },
  // 用户的表情列表
  getUserExpression(params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/queryMemberExpressionPageList',
      method: "get",
      params,
    })
  },
  // 用户收藏的表情列表
  getUserCollectionExpression(params) {
    return axios({
      url: '/jeecg-member/expression/expressionInfo/queryMemberCollectPageList',
      method: "get",
      params,
    })
  },
  // 其他接口…………
}
export default other
