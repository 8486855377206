// 上传的接口
import axios from '../axios'

const index = {
	// 获取上传签名 oss上传签名生成
	policy() {
		return axios.get(`/jeecg-member/public/oss/policy`);
	},
	// oss上传成功回调
	callback(params) {
		return axios.post(`/jeecg-member/public/oss/callback`, params, );
	},
	// 上传音乐
	uploadMusic(params) {
		return axios({
			url: '/jeecg-member/music/musicInfo/uploadMusic',
			method: "post",
			data: params,
		})
	},
	// 上传音乐记录列表
	getUploadMusicList(params) {
		return axios({
			url: '/jeecg-member/music/musicInfo/queryUploadPageList',
			method: "get",
			params,
		})
	},
	// 删除音乐
	deleteMusic(params) {
		return axios({
			url: `/jeecg-member/music/musicInfo/delete/${params.musicId}`,
			method: 'DELETE',
			params
		})
	},
	// 表情包上传
	expressionUpload(params) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/expressionUpload',
			method: "post",
			data: params,
		})
	},
	// 表情包修改
	expressionUpdate(expressionId, params) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/expressionUpdate/' + expressionId,
			method: "put",
			data: params,
		})
	},
	// 删除表情包
	deleteExpression(expressionId) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/expression/' + expressionId,
			method: "delete",
		})
	},
	// 表情包详情
	getExpressionDetail(expressionId) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/expressionDetail/' + expressionId,
			method: "get",
		})
	},
	// 上传表情包列表
	getUploadExpression(params) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/queryUploadPageList',
			method: "get",
			params,
		})
	},
	// 表情结算管理
	settlementManagement(params) {
		return axios({
			url: '/jeecg-member/expression/expressionInfo/settlementManagement',
			method: "get",
			params,
		})
	},
	// 音乐结算管理
	musicInfo(params) {
		return axios({
			url: '/jeecg-member/music/musicInfo/settlementManagement',
			method: "get",
			params,
		})
	},
}
export default index