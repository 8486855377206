import axios from 'axios'; // 引入axios
import baseURL from './baseUrl' //根路径
import {
	Message
} from 'element-ui'
import Vue from 'vue'
import router from '../router'
import store from '../store/index'
import JSONbig from 'json-bigint'
let tokenStatus = true
// 请求
var instance = axios.create({
	timeout: 1000 * 12,
	baseURL
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//可以通过axios的transformResponse方法，这个方法的作用是在传递给then/catch前，允许修改响应数据
/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
	// 状态码判断
	switch (status) {
		case 401:
			// 401: 未登录状态，跳转登录页
			// toLogin()去登陆
			localStorage.clear()
			Message.warning('您还未登录，请前往登录')
			setTimeout(() => {
				router.push('/login')
				// toLogin(); 去登陆
			}, 1000);
			break;
		case 403:
			// 403 token过期
			// 清除token并跳转登录页
			localStorage.removeItem('token');
			if (tokenStatus) {
				tokenStatus = false
				Message.warning('登录过期,请前往登录')
				setTimeout(() => {
					tokenStatus = true
				}, 2000)
			}
			setTimeout(() => {
				router.push('/login')
				// toLogin(); 去登陆
			}, 1000);
			break;
		case 404:
			// 404请求不存在
			Message.error('请求资源不存在')
			break;
		case 500:
			// 500 token非法无效 删除token 会显示这个错误
			if (other == 'token非法无效!' || other == 'Token失效，请重新登录!') {
				if (tokenStatus) {
					tokenStatus = false
					Message.warning('token非法无效!,请重新登录')
					setTimeout(() => {
						tokenStatus = true
					}, 2000)
					setTimeout(() => {
						router.push('/login')
						// toLogin(); 去登陆
					}, 1000);
				}
			} else {
				Message.error(other)
			}
			break;
		default:
			Message.error(other)
			console.log(other);
	}
}
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
	config => {
		// 登录流程控制中，根据本地是否存在token判断用户的登录情况        
		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
		//请求头要添加终端类型  platform  0 安卓   1 IOS苹果  2 PC端 
		const token = store.state.user.token; //token存在vuex的user模块
		config.headers.common['X-Access-Token'] = token
		config.headers.platform = 2 //pc端的platform为2
		return config;
	},
	error => Promise.error(error))
//axios在这里默认把响应体从json字符串转成了js对象  防止id精度丢失转换一下
instance.defaults.transformResponse = [function(data) {
	// console.log('如果id或者什么id的值过长会显示bigNumber,这个值使用toString()')
	//  console.log(data)//这里的data是字符串，在这个字符串的是没有丢失精度的，所以需要在这里先把精度调好
	try {
		//作用1：把json字符串转为js对象
		//作用2：把里面的大数字做安全处理
		//如果显示bigNumber 这个值就用toString
		return JSONbig.parse(data)
	} catch (err) {
		return data;
	}
	//return data;
}]
// 响应拦截器
instance.interceptors.response.use(
	// 请求成功  
	res => {
		if (res.status === 200) {
			if (res.data.code == 200) {
				return Promise.resolve(res.data)
			} else {
				Message.warning(res.data)
				return Promise.reject(res.data)
			}
		} else {
			return Promise.reject(res.data)
		}
	},
	// 请求失败
	error => {
		const {
			response
		} = error;
		if (response) {
			// 请求已发出，但是不在2xx的范围 
			errorHandle(response.status, response.data.message);
			return Promise.reject(response);
		} else {
			// 处理断网的情况
			// eg:请求超时或断网时，更新state的network状态
			// network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
			// 关于断网组件中的刷新重新获取数据，会在断网组件中说明
			if (!window.navigator.onLine) {
				store.commit('changeNetwork', false);
			} else {
				return Promise.reject(error);
			}
		}
	}
)
export default instance;