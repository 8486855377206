import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './element/element'
import 'element-ui/lib/theme-chalk/index.css';
import websoketUrl from './api/websoketUrl'
import VueAMap from 'vue-amap';
import { ryError } from './api/rongYunErro.js'
import base64toFile from "@/api/base64ChangeFile";
// 图片预览插件组件引入
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.use(VueAMap);
Vue.prototype.$ryError = ryError
Vue.prototype.$base64toFile = base64toFile
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: 'ae630ceffeaebd8ef81d457198bc8764',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11' // 版本号
});
Vue.prototype.$websoketUrl = websoketUrl
Vue.use(ElementUI);
//=================== 长链接实例 ===================
const socket = new WebSocket(websoketUrl);
socket.onopen = function (event) {
  console.log('WebSocket connection opened');
  let uuid = localStorage.getItem('uuid');
  let userStr = localStorage.getItem('loginMsg');
  if (uuid && userStr) {
    let obj = {};
    obj.cmd = "30000";
    obj.data = {
      memberId: JSON.parse(userStr).memberId,
      uuid: uuid,
    };
    console.log('send socket message', obj);
    socket.send(JSON.stringify(obj));
  }
};
socket.onerror = function (event) {
  console.error('WebSocket error observed:', event);
};
socket.onmessage = function (event) {
  console.log('Message from server ', event.data);
};
socket.onclose = function (event) {
  console.log('WebSocket is closed now.');
};
let reconnectInterval = null;
const reconnectDelay = 3000; // 重连间隔时间，单位毫秒
const maxReconnectAttempts = 5; // 最大重连次数
let currentAttempt = 0;
socket.onclose = function (event) {
  console.log('WebSocket is closed now.', event);
  if (event.wasClean) {
    console.log('Connection closed cleanly');
  } else {
    console.error('Connection died');
  }
  if (currentAttempt < maxReconnectAttempts) {
    currentAttempt++;
    reconnectInterval = setInterval(() => {
      console.log('Attempting to reconnect...', currentAttempt);
      this.connect(); // 假设你有一个connect方法重新建立连接
    }, reconnectDelay);
  } else {
    console.error('Maximum reconnection attempts reached');
  }
};
// 将WebSocket实例添加到Vue原型上，使其在所有组件中可用
Vue.prototype.$socket = socket;
//===============================================
// 引入接口
import api from './api/api'
// this.$api.index.接口名
Vue.prototype.$api = api
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
